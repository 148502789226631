import React, { useEffect } from 'react';
import { appRouter, authenticationRouter } from 'router';
import { RouterProvider } from 'react-router-dom';
import useCheckRouterValid from 'hook/useCheckRouterValid';
import { message } from 'antd';
import { useRecoilState, useRecoilValue } from 'recoil';
import { messageFeedback, userInformation } from 'recoil/atom/authentication';

const App = () => {
  const { checkRouterValid } = useCheckRouterValid();
  const { role } = useRecoilValue(userInformation);
  const [messageApi, contextHolder] = message.useMessage();
  const [showMessageFeedback, setShowMessageFeedback] =
    useRecoilState(messageFeedback);

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  useEffect(() => {
    window.addEventListener('keypress', handleKeyPress);

    if (showMessageFeedback.display) {
      messageApi
        .open({
          type: showMessageFeedback.type,
          content: showMessageFeedback.content,
        })
        .then(() =>
          setShowMessageFeedback({
            display: false,
            content: '',
            type: 'error',
          }),
        );
    }

    return window.removeEventListener('keypress', handleKeyPress);
  }, [messageApi, showMessageFeedback, setShowMessageFeedback]);

  return (
    <>
      {contextHolder}
      <RouterProvider
        router={checkRouterValid ? appRouter[role] : authenticationRouter}
      />
    </>
  );
};

export default App;
