import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useMutation } from 'react-query';
import { loginToApp } from 'api/authentication/authen.api';
import Link from 'components/Link';
import useMultiLanguageSignIn from 'page/Authentication/SignIn/hook/useMultiLanguageSignIn';
import useSetTitlePage from 'hook/useSetTitlePage';
import CheckboxCustom from 'components/Checkbox';
import {
  ValidateEmail,
  ValidatePasswordEmpty,
} from 'page/Authentication/component/Input';
import BtnSubmitFullSize from 'page/Authentication/component/Button/BtnSubmitFullSize';
import useRemoveBlankSpace from 'hook/useRemoveBlankSpace';
import { Alert, Form } from 'antd';
import {
  FormItemStyled,
  TitleAuthentication,
} from 'page/Authentication/styled';
import { DASHBOARD_PATH } from 'constant/appPath';
import {
  authenticationToken,
  partnerIdRecoil,
  userAccount,
  userInformation,
} from 'recoil/atom/authentication';
import { version } from 'recoil/atom/version';
import { useNavigate } from 'react-router-dom';
import useErrorCodeSignIn from 'page/Authentication/SignIn/hook/useErrorCodeSignIn';
import useCheckErrorCode from 'hook/useCheckErrorCode';

const SignIn = () => {
  const { SIGN_IN_TITLE, SIGN_IN_REMEMBER_ME, SIGN_IN_FORGOT_PASSWORD } =
    useMultiLanguageSignIn();
  useSetTitlePage('Solitonパートナーサイト│ログイン');
  const navigate = useNavigate();
  const [rememberUser, setRememberUser] = useState(false);
  const [loading, setLoading] = useState(false);
  const [, setToken] = useRecoilState(authenticationToken);
  const [, setUserInformation] = useRecoilState(userInformation);
  const [, setPartnerId] = useRecoilState(partnerIdRecoil);
  const appVersion = useRecoilValue(version);
  const [currentUserAccount, setCurrentUserAccount] =
    useRecoilState(userAccount);
  const { ERROR_CODE_SIGN_IN } = useErrorCodeSignIn();
  const { handleClearErrorMessage, handleRenderMessage, errorMessage } =
    useCheckErrorCode({ errorCode: ERROR_CODE_SIGN_IN });
  const { handleTrimInput } = useRemoveBlankSpace();

  const [form] = Form.useForm();

  const { mutate, isError } = useMutation({
    mutationFn: loginToApp,
    onSuccess: (response) => {
      setLoading(false);
      handleClearErrorMessage();
      let errorCode = (response.ec ??= false);
      const formData = form.getFieldsValue(true);
      const responseData = response.data;
      // save token to localStorage
      if (errorCode === false) {
        setPartnerId({
          partnerId: responseData?.userResponse?.partnerId,
        });
        setToken({
          accessToken: responseData.accessToken,
          passwordExpiredRemainingDay: responseData.passwordExpiredRemainingDay,
        });

        setUserInformation({
          ...responseData?.userResponse,
        });
        // save user & password to localStorage
        if (rememberUser) {
          setCurrentUserAccount({
            email: formData.email,
            password: formData.password,
            remember: true,
          });
        } else {
          setCurrentUserAccount({
            email: '',
            password: '',
            remember: false,
          });
        }

        navigate(DASHBOARD_PATH);
      }
    },
    onError: (response) => {
      setLoading(false);
      handleRenderMessage(response);
    },
  });

  const onFinish = (values) => {
    const payload = {
      email: values.email,
      password: values.password,
    };
    setLoading(true);
    mutate(payload);
  };

  useEffect(() => {
    if (currentUserAccount.email !== '' && currentUserAccount.password !== '') {
      form.setFieldsValue({
        email: currentUserAccount.email,
        password: currentUserAccount.password,
      });
      setRememberUser(currentUserAccount.remember);
    }
  }, [form, currentUserAccount]);

  return (
    <>
      <div className="form-responsive">
        <TitleAuthentication className="title-form-sign-in-responsive">
          {SIGN_IN_TITLE}
        </TitleAuthentication>

        {/* show error when call API */}
        {(isError || errorMessage) && (
          <Alert
            message={errorMessage}
            type="error"
            style={{ marginBottom: '24px' }}
          />
        )}

        {/* start form sign in */}
        <Form
          form={form}
          name="normal_login"
          className="size"
          onFinish={onFinish}
          layout={'vertical'}
        >
          {/* start email */}
          <ValidateEmail
            name="email"
            onChange={(e) => handleTrimInput(form, 'email', e.target.value)}
          />
          {/* end email */}

          {/* start password */}
          <ValidatePasswordEmpty
            name="password"
            onChange={(e) => handleTrimInput(form, 'password', e.target.value)}
          />
          {/* end password */}

          {/* start checkbox remember account - link navigate to forgot password */}
          <FormItemStyled marginbottom="15px">
            <div className="text-wrapper">
              <Form.Item name="remember" valuePropName="checked" noStyle>
                <CheckboxCustom
                  text={SIGN_IN_REMEMBER_ME}
                  value={rememberUser}
                  onChange={(e) => {
                    setRememberUser(e.target.checked);
                  }}
                />
              </Form.Item>
              <div>
                <Link to="/forgot-password" text={SIGN_IN_FORGOT_PASSWORD} />
              </div>
            </div>
          </FormItemStyled>
          {/* end checkbox remember account - link navigate to forgot password */}

          {/* start button sign in */}
          <BtnSubmitFullSize
            button="login"
            formName="sign-in"
            loading={loading}
          />
          {/* end button sign in */}
        </Form>
      </div>
      <div className="blank-space-sign-in">
        {window.location.host === 'localhost:3000' ? (
          <span>
            Version: {appVersion.current} -- Last update:{' '}
            {new Date(appVersion.timestamp * 1000).toLocaleString('vi')}
          </span>
        ) : null}
      </div>
    </>
  );
};

export default SignIn;
