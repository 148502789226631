import axios from 'axios';
import { ERROR_CODE_RESPONSE } from 'constant/errorCode';

const TIME_OUT = 10000;

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  timeout: TIME_OUT,
  headers: {
    'Content-Type': 'application/json',
  },
});

export default axiosClient;

// Add a request interceptor
axiosClient.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    let authenticationData = JSON.parse(localStorage.getItem('user_token'));
    // config.headers['Content-Type'] = "application/json";
    // config.headers['x-api-key'] = process.env.REACT_APP_X_API_KEY;
    if (authenticationData && authenticationData.accessToken) {
      config.headers['authorization'] =
        'Bearer ' + authenticationData.accessToken;
    }
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  },
);

// Add a response interceptor
axiosClient.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response.data;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    if (
      error.code === 'ERR_BAD_REQUEST' &&
      ERROR_CODE_RESPONSE[error.response.data.message]
    ) {
      localStorage.removeItem('user_token');
      localStorage.removeItem('password_expire');
      window.location.replace('/');
      window.location.reload();
    } else if (
      error.code === 'ERR_BAD_REQUEST' &&
      error.response.data.message === '410_PASSWORD_EXPIRED'
    ) {
      let userToken = localStorage.getItem('user_token');
      const expired = {
        accessToken: JSON.parse(userToken).accessToken,
        passwordExpiredRemainingDay: 0,
      };
      window.location.replace('/');
      localStorage.setItem('user_token', JSON.stringify(expired));
    }
    return Promise.reject(error);
  },
);
